<template>
  <div
    v-if="product"
    :class="{ loading: loading }"
    class="desktop-product-hover"
  >
    <div
      v-if="!product.inStock && product.isOnesize && notifyMeEnabled"
      class="caption button solid"
      @click="showProductRestockSidebar()"
    >
      <span class="label">
        {{ $t('Product_Details_Notify_Me') }}
      </span>
    </div>
    <button
      v-if="!isBundle() && product.inStock"
      class="caption button solid"
      :disabled="loading || !product.inStock || cartExceedStock"
      @click="addToCart()"
    >
      <template v-if="!loading">
        <span class="label">
          {{
            !product.inStock || cartExceedStock
              ? $t('Global_Out_Of_Stock')
              : $t('Product_Details_Add_To_Cart')
          }}
        </span>
      </template>
      <template v-else>
        <span class="label">
          <span class="spinner2" />
        </span>
      </template>
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ProductCardHover',
  props: {
    product: {
      type: Object,
      required: true,
    },
    parentLoading: {
      type: Boolean,
      default: false,
    },
    cartExceedStock: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: null,
    confirming: false,
  }),

  computed: {
    ...mapState({
      notifyMeEnabled: (state) => state.ui['restock-notification'].isEnabled,
    }),
  },
  watch: {
    parentLoading(value) {
      this.loading = value
    },
  },
  methods: {
    addToCart() {
      this.loading = true
      this.$emit('addToCart')
    },
    showProductRestockSidebar() {
      const transformedProduct = this.transformProduct(this.product)
      this.selectedSize = transformedProduct.sizeOptions[0].value
      this.$store.dispatch('ui/restock-notification/show', {
        itemId: this.selectedSize,
        product: this.product,
      })
    },
    isBundle() {
      return this.product?.bundleInfo?.type === 'flexible'
    },
  },
}
</script>

<style lang="scss" scoped>
.desktop-product-hover {
  background-color: transparent;

  .caption {
    width: 100%;

    @media (hover: hover) {
      &:hover:disabled {
        cursor: default;
      }
    }
  }
}
</style>
