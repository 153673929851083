export default function ({ store }) {
  if (process.client) {
    const { currentCountryCode } = store.state.frontend
    let apiKey
    switch (currentCountryCode) {
      case 'no':
      case 'NO':
        apiKey = 'f4ae05eef69d6fd476ea188b'
        break
      case 'fi':
      case 'FI':
        apiKey = '3fa6e4f4caf64114bb0cf856'
        break
      case 'se':
      case 'SE':
        apiKey = '134d6e36c5e8929c812b2a95'
        break
      default:
        apiKey = '758ad0121dad51582b50a488'
    }
    window.lipscoreInit = function () {
      lipscore.init({ apiKey })
    }
  }
}
