<template>
  <div
    v-if="canDisplay"
    class="product-card"
    :class="[
      contextSearch ? 'context-search' : '',
      { 'has-hover-image': hasHoverImage },
      { 'out-of-stock': !isInStock },
      { loading: loading },
    ]"
    @mouseenter="mouseOver"
    @mouseleave="mouseOut"
  >
    <div class="hover-wrapper image">
      <div class="ratio">
        <div v-if="!loaded" class="spinner" />
        <Vue2Image
          :src="imageUrl"
          :width="1200"
          :widths="[300, 600, 900, 1200]"
          :quality="80"
          loading="lazy"
          :alt="mutableProduct.name"
          class="product-image main"
          @imageLoaded="imageLoaded"
        />
        <Vue2Image
          v-if="loaded && canHover && hasHoverImage"
          :src="hoverImageUrl"
          :width="1200"
          :widths="[300, 600, 900, 1200]"
          :quality="80"
          loading="lazy"
          :alt="mutableProduct.name"
          class="hide-on-mobile product-image hover"
          :class="!hovering ? 'is-hidden' : ''"
        />
        <template v-for="(badge, index) in mutableProduct.badge">
          <SpinningBadge
            :key="index"
            :badge="badge"
            class="spinning-badge"
            :class="{ 'is-white': hovering && hasHoverImage }"
          />
        </template>

        <div
          v-if="mutableProduct.usp && mutableProduct.usp.length"
          v-show="!showCartIcon || (!hovering && showCartIcon)"
          class="product-usps"
        >
          <div
            v-for="(usp, index) in mutableProduct.usp"
            :key="index"
            class="usp"
          >
            {{ usp.label }}
          </div>
        </div>
        <mp-link
          :to="$u(mutableProduct.url)"
          class="pdp-link"
          @click.native="trackClick"
        />
        <ProductCardHover
          v-if="showCartIcon && hovering"
          class="hide-on-mobile"
          :product="mutableProduct"
          :parent-loading="loading"
          :cart-exceed-stock="cartExceedStock"
          @addToCart="addToCart"
        />
      </div>
    </div>
    <div class="details">
      <span
        class="lipscore-rating-small"
        :data-ls-product-name="mutableProduct.name"
        data-ls-brand="xlash"
        :data-ls-product-id="mutableProduct?.sku"
        :data-ls-product-url="mutableProduct.url"
        data-ls-readonly="true"
      />
      <div class="details__row">
        <mp-link
          :to="$u(mutableProduct.url)"
          class="pdp-link"
          @click.native="trackClick"
        >
          <div class="name">
            <p>
              <span class="title">
                {{ mutableProduct.name }}
              </span>
              <br />
              <span v-if="showProductType" class="type">
                {{ mutableProduct.product_type }}
              </span>
            </p>
          </div>
          <div class="short-description">
            {{ mutableProduct.shortDescription }}
          </div>
        </mp-link>

        <div class="price">
          <p :class="{ 'sale-price': mutableProduct.discounted }">
            {{ mutableProduct.price }}
          </p>
          <p v-if="mutableProduct.discounted" class="old-price">
            {{ mutableProduct.priceBeforeDiscount }}
          </p>
        </div>
      </div>
      <ProductSwatch
        size="small"
        :product-id="mutableProduct.product"
        :swatches="swatchProducts"
        @setSwatch="setSwatchProduct"
      />
      <div v-if="showCartIcon" class="add-to-cart hide-on-desktop">
        <div
          v-if="
            !mutableProduct.inStock &&
            mutableProduct.isOnesize &&
            notifyMeEnabled
          "
          class="button medium solid atc"
          @click="showProductRestockSidebar()"
        >
          <span class="label">
            {{ $t('Product_Details_Notify_Me') }}
          </span>
        </div>
        <button
          v-if="!isBundle() && mutableProduct.inStock"
          class="button medium solid atc"
          :class="{ loading: loading }"
          :disabled="loading || !mutableProduct.inStock || cartExceedStock"
          @click="addToCart"
        >
          <template v-if="!loading">
            <span class="label">
              {{
                !mutableProduct.inStock
                  ? $t('Global_Out_Of_Stock')
                  : $t('Product_Details_Add_To_Cart')
              }}
            </span>
          </template>
          <template v-else>
            <span class="label">
              <span class="spinner2" />
            </span>
          </template>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import ProductCardMixin from '@made-people/centra-storyblok-nuxt-shared/lib/mixins/ProductCardMixin'
import Vue2Image from '@made-people/vue2-image'
import ViewportMixin from '@made-people/centra-storyblok-nuxt-shared/lib/mixins/viewport'
import ProductCardHover from '~/components/product-card-hover/ProductCardHover'
import ProductSwatch from '~/components/product-swatch/ProductSwatch.vue'
import { lipscoreInitWidgetsDebounced } from '~/lib/lipscore'

export default {
  name: 'ProductCard',
  components: {
    ProductCardHover,
    ProductSwatch,
    Vue2Image,
  },
  mixins: [ProductCardMixin, ViewportMixin],
  props: {
    gtmListName: {
      type: String,
      default() {
        return this.$route.path.replace(/(^\/[^/]+\/?)|(\/$)/g, '')
      },
    },
    position: {
      type: Number,
      required: true,
    },
    showCartIcon: {
      type: Boolean,
      default: false,
    },
    showProductType: {
      type: Boolean,
      default: false,
    },
    showTrashIcon: {
      type: Boolean,
      default: false,
    },
    contextSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
    mobileDisplaySize: {
      type: String,
      default: '',
    },
    desktopDisplaySize: {
      type: String,
      default: '50vw',
    },
    mobileRoundedCorners: {
      type: Boolean,
      default: false,
    },
    desktopRoundedCorners: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hovering: false,
      loaded: false,
      canHover: false,
      desktop: false,
      swatchProduct: null,
      mutableProduct: { ...this.product },
    }
  },
  computed: {
    ...mapState({
      notifyMeEnabled: (state) => state.ui['restock-notification'].isEnabled,
    }),
    ...mapGetters({
      cart: 'centra-cart/cart',
    }),
    isOnesize() {
      return this.mutableProduct.isOnesize
    },
    canDisplay() {
      return !!this.mutableProduct
    },
    swatchProducts() {
      return this.mutableProduct.swatchProducts
    },
    hasHoverImage() {
      return true
    },
    imageUrl() {
      const media = this.mutableProduct.media?.[0]
      if (typeof media === 'object' && media !== null) {
        return media.sources?.full?.url || ''
      }
      return media || ''
    },
    hoverImageUrl() {
      const media = this.mutableProduct.media?.[1]
      if (typeof media === 'object' && media !== null) {
        return media.sources?.full?.url || ''
      }

      return media || ''
    },
    cartExceedStock() {
      let cartExceedStock
      if (this.cart && this.cart.items && this.cart.items)
        this.cart.items.forEach((item) => {
          if (this.mutableProduct.product === item.product)
            cartExceedStock = item.quantity + 1 > this.stock
        })
      return cartExceedStock
    },
    stock() {
      return this.mutableProduct?.sizeOptions?.[0]?.quantity || 0
    },
  },
  mounted() {
    lipscoreInitWidgetsDebounced()
    this.canHover =
      window.matchMedia('(hover: hover)').matches && this.hoverImageUrl

    this.initDesktopQuery()
  },
  created() {
    this.loadProduct = this.$debounce(this._loadProduct, 100)
  },
  methods: {
    setSwatchProduct(swatch) {
      this.$store
        .dispatch('centra-product/lookupSwatchProduct', swatch.product)
        .then((swatchProd) => {
          this.mutableProduct = swatchProd
        })
        .catch((error) => {
          console.error('Error fetching product:', error)
        })
    },
    inViewportHandler() {
      if (!this.seenInViewport && this.testInViewport()) {
        const list =
          this.gtmListName ||
          this.$route.path.replace(/(^\/[^/]+\/?)|(\/$)/g, '')
        this.gtm_queueProductImpression(
          this.mutableProduct,
          list,
          this.position
        )
      }
    },
    imageLoaded() {
      this.loaded = true
    },
    mouseOver() {
      this.hovering = true
    },
    mouseOut() {
      this.hovering = false
    },
    trackClick() {
      this.gtm_trackProductClick(this.mutableProduct, this.gtmListName)
    },
    addToCart() {
      const transformedProduct = this.transformProduct(this.mutableProduct)
      if (this.mutableProduct.isOnesize) {
        this.selectedSize = transformedProduct.sizeOptions[0]
      }
      if (this.selectedSize) {
        this.loading = true
        this.selectedSize.addToCart().finally(() => {
          this.loading = false
          this.$store.dispatch('ui/added-to-cart/showAddedToCart', {
            addedProduct: this.mutableProduct,
            addedProductSize: this.selectedSize.label,
            desktop: this.desktop,
          })
        })
      }
    },
    initDesktopQuery() {
      const desktopQuery = window.matchMedia('(min-width: 1024px)')
      this.desktop = desktopQuery.matches

      desktopQuery.addEventListener('change', (e) => {
        this.desktop = e.matches
      })
    },
    showProductRestockSidebar() {
      const transformedProduct = this.transformProduct(this.mutableProduct)
      this.selectedSize = transformedProduct.sizeOptions[0].value
      this.$store.dispatch('ui/restock-notification/show', {
        itemId: this.selectedSize,
        product: this.mutableProduct,
      })
    },
    isBundle() {
      return this.product?.bundleInfo?.type === 'flexible'
    },
  },
}
</script>

<style lang="scss" scoped>
.product-card {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .image {
    overflow: hidden;
    position: relative;
    @include aspect-ratio(3, 4);
    background-color: $grey;
    flex-shrink: 0;

    .pdp-link {
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .product-image {
      z-index: 1;
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;

      &.is-hidden {
        z-index: -1;
        opacity: 0;
      }

      img {
        object-fit: cover;
      }

      &::v-deep {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .spinning-badge {
      z-index: 1;
      position: absolute;
      left: 1.2rem;
      top: 1.2rem;
      width: 50px;
      height: 50px;
    }

    .product-usps {
      z-index: 1;
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      display: flex;
      justify-content: flex-start;
      padding: 0.6rem;
      flex-wrap: wrap;

      .usp {
        margin: 0.2rem;
        border: 1px solid $grey-light;
        border-radius: 0.4rem;
        @include p--small;
        font-size: 0.9rem;
        height: 1.7rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        padding: 0 0.4rem;
      }
    }
  }

  .details {
    padding-top: 1.6rem;
    padding-left: spacing('xxsmall');
    padding-right: spacing('xxsmall');
    gap: spacing('xxsmall');

    .lipscore-rating-small:empty {
      display: none;
    }

    flex-basis: 100%;
    display: flex;
    flex-direction: column;

    > *:last-child {
      margin-top: auto;
    }

    .name {
      p {
        @include p--medium;

        line-height: 120%;
        font-weight: 600;
        margin: 0;
        text-transform: none;
      }
    }

    .short-description {
      @include p--small;
    }

    .price {
      display: flex;
      flex-wrap: wrap;
      margin-top: 0.3em;
      column-gap: spacing('xxsmall');
      row-gap: 0;

      p {
        @include p--medium;

        font-size: 1.6rem;
        font-weight: 600;
        margin: 0;
      }

      .sale-price {
        color: $blue;
      }

      .old-price {
        text-decoration: line-through;
      }
    }

    .add-to-cart {
      padding-top: spacing('xxsmall');

      .atc {
        position: relative;
        width: 100%;

        &:last-of-type {
          margin-bottom: 0;
        }

        @media (hover: hover) {
          &:hover:disabled {
            cursor: default;
          }
        }

        .spinner2 {
          border-top-color: $white;
          border-right-color: $white;
          border-bottom-color: $white;
        }

        &.loading {
          .label {
            opacity: 1;
          }
        }
      }
    }
  }

  .size-selector {
    position: absolute;
    bottom: 0;
  }
}

// Mobile Only
@media screen and (max-width: $tablet) {
  .product-card {
    .details {
      .add-to-cart {
        .atc {
          .label {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }
  }
}

// Tablet (Portrait)
@media screen and (min-width: $tablet) {
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .product-card {
    .image {
      .spinning-badge {
        $position: 3rem;
        $size: 8rem;

        top: $position;
        left: $position;
        width: $size;
        height: $size;

        &.is-white {
          ::v-deep {
            .badge {
              svg {
                fill: $white;
              }
            }
          }
        }
      }

      .product-usps {
        .usp {
          font-size: 1rem;
        }
      }
    }

    .details {
      display: flex;
      flex-direction: column;

      .pdp-link,
      .price {
        gap: 0.4rem;
      }

      .pdp-link {
        flex-grow: 3;
        display: flex;
        flex-direction: column;
      }

      .price {
        flex-grow: 1;
        display: inline-flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 0;

        p {
          margin-top: 0;
          line-height: 120%;
        }
      }

      .swatch-wrapper {
        width: 100%;
      }
    }

    /**
      * Hover Product Card
      */
    @media (hover: hover) {
      .hover-wrapper {
        position: relative;
        .ratio {
          .product-image {
            position: absolute;
            transition: transform 1s cubic-bezier(0.5, 0, 0, 1),
              opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
          }
        }
      }
      &:hover {
        .product-image.hover {
          transform: scale(1.02);
        }

        .desktop-product-hover {
          z-index: 2;
          width: calc(100% - 3.2rem);
          display: block;
          position: absolute;
          bottom: 1.6rem;
          left: 1.6rem;
          pointer-events: all;
        }
      }
    }
  }
}

// Laptop
@media screen and (min-width: $laptop) {
  .product-card {
    .details {
      .details__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .pdp-link {
        max-width: 65%;
      }

      .name {
        p {
          @include p--large($font-base);
          line-height: 120%;
        }
      }
    }
  }
}

// Desktop
@media screen and (min-width: $desktop) {
}
</style>
